import React, { Suspense, lazy } from "react";

const AboutIntro = lazy(() => import("../components/AboutIntro"));
const AboutDirector = lazy(() => import("../components/AboutDirector"));

const About = () => {
  return (
    <div className="relative z-20 flex flex-col justify-between">
      <Suspense fallback={<div>Loading...</div>}>
        <AboutIntro />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <AboutDirector />
      </Suspense>
    </div>
  );
};

export default About;
