import React, { Suspense, lazy } from "react";

const FAQQuestions = lazy(() => import("../components/FAQQuestions"));

const FAQ = () => {
  return (
    <div className="relative z-20 flex flex-col  ">
      <Suspense fallback={<div>Loading...</div>}>
        <FAQQuestions />
      </Suspense>
    </div>
  );
};

export default FAQ;
