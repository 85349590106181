import React, { Suspense, lazy } from "react";

// Dynamically import the components
const HomeCallToAction = lazy(() => import("../components/HomeCallToAction"));
const HomeReviews = lazy(() => import("../components/HomeReviews"));
const HomeTeachers = lazy(() => import("../components/HomeTeachers"));
const HomeLocations = lazy(() => import("../components/HomeLocations"));

function Home({ setFacebookLink, triggerAnimation }) {
  return (
    <div className="relative z-20 flex flex-col justify-between">
      <Suspense fallback={<div>Loading...</div>}>
        <HomeCallToAction />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <HomeReviews />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <HomeTeachers />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <HomeLocations
          setFacebookLink={setFacebookLink}
          triggerAnimation={triggerAnimation}
        />
      </Suspense>
    </div>
  );
}

export default Home;
