import React from "react";
// Circle graphics for page background
import OrangeCircle from "../assets/orange_circle.svg";
import BlueCircle from "../assets/blue_circle.svg";

// Circles in order from top to bottom
const Background = () => {
  return (
    <div id="top" className="absolute w-full h-[800vh] overflow-hidden z-0">
      <img
        src={BlueCircle}
        alt="Blue Circle"
        className="absolute top-[10vh] left-[4vw] w-[25vw] h-[25vw]"
      />
      <img
        src={OrangeCircle}
        alt="Orange Circle"
        className="absolute top-[30vh] left-[75vw] w-[40vw] h-[40vw]"
      />
      <img
        src={BlueCircle}
        alt="Blue Circle"
        className="absolute top-[110vh] left-[-7vw] w-[20vw] h-[20vw]"
      />
      <img
        src={OrangeCircle}
        alt="Orange Circle"
        className="absolute top-[175vh] left-[73vw] w-[15vw] h-[15vw]"
      />
      <img
        src={OrangeCircle}
        alt="Orange Circle"
        className="absolute top-[200vh] left-[10vw] w-[20vw] h-[20vw]"
      />
      <img
        src={BlueCircle}
        alt="Blue Circle"
        className="absolute top-[260vh] left-[85vw] w-[20vw] h-[20vw]"
      />
      <img
        src={BlueCircle}
        alt="Blue Circle"
        className="absolute top-[300vh] left-[-13vw] w-[40vw] h-[40vw]"
      />
      <img
        src={OrangeCircle}
        alt="Orange Circle"
        className="absolute top-[395vh] left-[75vw] w-[20vw] h-[20vw]"
      />
      <img
        src={BlueCircle}
        alt="Blue Circle"
        className="absolute top-[410vh] left-[4vw] w-[25vw] h-[25vw]"
      />
      <img
        src={OrangeCircle}
        alt="Orange Circle"
        className="absolute top-[430vh] left-[75vw] w-[40vw] h-[40vw]"
      />
      <img
        src={BlueCircle}
        alt="Blue Circle"
        className="absolute top-[510vh] left-[-7vw] w-[20vw] h-[20vw]"
      />
      <img
        src={OrangeCircle}
        alt="Orange Circle"
        className="absolute top-[575vh] left-[73vw] w-[15vw] h-[15vw]"
      />
      <img
        src={OrangeCircle}
        alt="Orange Circle"
        className="absolute top-[600vh] left-[10vw] w-[20vw] h-[20vw]"
      />
      <img
        src={BlueCircle}
        alt="Blue Circle"
        className="absolute top-[660vh] left-[85vw] w-[20vw] h-[20vw]"
      />
      <img
        src={BlueCircle}
        alt="Blue Circle"
        className="absolute top-[700vh] left-[-13vw] w-[40vw] h-[40vw]"
      />
      <img
        src={OrangeCircle}
        alt="Orange Circle"
        className="absolute top-[795vh] left-[75vw] w-[20vw] h-[20vw]"
      />
    </div>
  );
};

export default Background;
