import React from "react";

const Register = () => {
  return (
    <div className="container mx-auto py-8">
      <h1>Register</h1>
    </div>
  );
};

export default Register;
