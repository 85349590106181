import React, { useState, useEffect } from "react";

function Footer({ facebookLink, animate }) {
  const [animateClass, setAnimateClass] = useState("");
  const instagramLink = "https://www.instagram.com/infinity_danceco/";

  // Handle FB icon animation any time footer is passed new animate value
  useEffect(() => {
    if (animate) {
      setAnimateClass("animate-bounce"); // Tailwind animation class
    } else {
      setAnimateClass("");
    }
  }, [animate]);

  return (
    <footer
      id="footer"
      style={{
        background: "linear-gradient(to bottom right, #215d73, #3eafd9)",
      }}
      className="p-4 h-[5rem] md:h-[3.6rem] w-full z-50 flex"
    >
      <div className="container mx-auto flex items-center justify-around md:justify-between">
        {/* An empty div for spacing, as I am an excellent computer programmer */}
        <div className="w-1/3 hidden md:block"></div>
        {/* Copyright */}
        <p className="text-center  text-white">
          &copy; 2024 Infinity Dance Company
        </p>
        {/* Icons */}
        <div className="w-1/3 flex justify-end">
          <div className="flex space-x-2">
            {/* Instagram icon */}
            <a
              href={instagramLink}
              target="_blank"
              rel="noopener noreferrer"
              className={"ml-auto"}
            >
              <img
                src="/assets/Insta_Icon_White.png"
                alt="IDC Instagram Page"
                className="w-16 md:w-12 h-16 md:h-12"
              />
            </a>
            {/* Facebook icon */}
            <a
              href={facebookLink}
              target="_blank"
              rel="noopener noreferrer"
              className={`ml-auto ${animateClass}`}
            >
              <img
                src="/assets/FB_Icon_White.png"
                alt="IDC Facebook Page"
                className="w-16 md:w-12 h-16 md:h-12"
              />
            </a>
          </div>
        </div>
      </div>
      {/* Discontinued scroll button */}
      {false && (
        <a
          href="#top"
          className="z-50 absolute right-4 bottom-16 md:bottom-20 bg-primary_blue text-xl font-bold text-white  py-2 px-4 rounded-full shadow-lg border border-transparent hover:border-accent_light hover:underline "
        >
          Back to Top
        </a>
      )}
    </footer>
  );
}

export default Footer;
