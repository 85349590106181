import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseChimney,
  faHouse,
  faBars,
  faX,
  faBookOpen,
  faQuestion,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";

// Navbar page items
const navItems = [
  {
    type: "normal",
    label: "Home",
    destination: "/",
    turnedOn: true,
    icon: faHouseChimney,
  },
  {
    type: "normal",
    label: "About",
    destination: "/about",
    turnedOn: true,
    icon: faBookOpen,
  },
  {
    type: "normal",
    label: "Events",
    destination: "events",
    turnedOn: false,
    icon: faHouse,
  },
  {
    type: "normal",
    label: "FAQ",
    destination: "faq",
    turnedOn: true,
    icon: faQuestion,
  },
  {
    type: "action",
    label: "Contact",
    destination: "/contact",
    turnedOn: true,
    icon: faAddressCard,
  },
  {
    type: "action",
    label: "Register",
    destination: "/register",
    turnedOn: false,
    icon: faHouse,
  },
  {
    type: "action",
    label: "Buy Tickets",
    destination: "/buytickets",
    turnedOn: false,
    icon: faHouse,
  },
  {
    type: "button",
    label: "Login",
    destination: "/login",
    turnedOn: false,
    icon: faHouse,
  },
];

const Navbar = () => {
  // Boolean for mobile navbar
  const [isMobileNavOn, setIsMobileNavOn] = useState(false);

  return (
    <nav className="fixed top-0 h-[7rem] md:h-[4.5rem] w-full flex justify-center relative">
      {/* Navbar items row */}
      <div
        style={{
          background: "linear-gradient(to bottom right, #215d73, #3eafd9)",
        }}
        className="w-full px-4 z-50  flex justify-between items-center"
      >
        {/* Logo */}
        <img
          src="/assets/Logo.png"
          alt="Infinity Dance Company logo"
          className="h-[6rem] md:h-[4rem]  top-0 pt-1"
        />

        {/* Hamburger button for mobile viewports */}
        <button
          onClick={() => setIsMobileNavOn(!isMobileNavOn)}
          className="lg:hidden text-white focus:outline-none mr-4"
        >
          <FontAwesomeIcon
            icon={isMobileNavOn ? faX : faBars}
            className="h-12 md:h-7"
          />
        </button>

        {/* Desktop navbar item list */}
        <ul
          className={`space-x-10 relative hidden lg:flex top-auto w-auto bg-transparent `}
        >
          {navItems.map(
            (navItem) =>
              navItem.turnedOn && (
                <li
                  key={`Desktop-${navItem.label}NavItem`}
                  className="px-6 lg:px-0 lg:py-0 py-3"
                  onClick={() => setIsMobileNavOn(false)}
                >
                  <NavLink
                    to={navItem.destination}
                    end
                    className={({ isActive }) => {
                      // Determine nav button style via its .type
                      const baseStyle = "text-lg";
                      const activeStyle = isActive
                        ? "font-bold underline"
                        : "hover:underline";

                      if (navItem.type === "action") {
                        return `text-secondary_orange ${baseStyle} ${activeStyle}`;
                      } else if (navItem.type === "button") {
                        return `text-white bg-primary_blue rounded-full py-2 px-5 border shadow-lg ${baseStyle} ${
                          isActive
                            ? "border-accent_light font-bold underline"
                            : "border-transparent hover:underline hover:border-accent_light"
                        }`;
                      }
                      // Default fallback style if none of the conditions match
                      return `text-white ${baseStyle} ${activeStyle}`;
                    }}
                  >
                    {/* Icon */}
                    <FontAwesomeIcon icon={navItem.icon} className="pr-3" />
                    {navItem.label}
                  </NavLink>
                </li>
              )
          )}
        </ul>
      </div>
      {/* Mobile navbar item list */}
      <div className="z-40">
        <ul
          className={`absolute lg:hidden top-full w-full left-0 bg-accent_dark transition-transform duration-500 ${
            isMobileNavOn
              ? "transform translate-y-0"
              : "transform -translate-y-[150%] md:-translate-y-[175%]"
          } `}
        >
          {navItems.map(
            (navItem) =>
              navItem.turnedOn && (
                <li
                  key={`Mobile-${navItem.label}NavItem`}
                  className="px-6 lg:px-0 lg:py-0 py-3"
                  onClick={() => setIsMobileNavOn(false)}
                >
                  <NavLink
                    to={navItem.destination}
                    end
                    className={({ isActive }) => {
                      // Determine nav button style via its .type
                      const baseStyle = "text-lg";
                      const activeStyle = isActive
                        ? "font-bold underline"
                        : "hover:underline";

                      if (navItem.type === "action") {
                        return `text-secondary_orange ${baseStyle} ${activeStyle}`;
                      } else if (navItem.type === "button") {
                        return `text-white bg-primary_blue rounded-full py-2 px-5 border shadow-lg ${baseStyle} ${
                          isActive
                            ? "border-accent_light font-bold underline"
                            : "border-transparent hover:underline hover:border-accent_light"
                        }`;
                      }
                      // Default fallback style if none of the conditions match
                      return `text-white ${baseStyle} ${activeStyle}`;
                    }}
                  >
                    {/* Icon */}
                    <FontAwesomeIcon icon={navItem.icon} className="pr-3 w-6" />
                    {navItem.label}
                  </NavLink>
                </li>
              )
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
