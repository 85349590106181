import React, { Suspense, lazy } from "react";

const ContactForm = lazy(() => import("../components/ContactForm"));

const Contact = () => {
  return (
    <div className="relative z-20 flex flex-col">
      <Suspense fallback={<div>Loading...</div>}>
        <ContactForm />
      </Suspense>
    </div>
  );
};

export default Contact;
