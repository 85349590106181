import React, { useState, useEffect } from "react";
import Background from "./components/Background";
import Navbar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Events from "./pages/Events";
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";
import Register from "./pages/Register";
import BuyTickets from "./pages/BuyTickets";
import Login from "./pages/Login";
import Footer from "./components/Footer";
import "@fortawesome/fontawesome-free/css/all.css";
import ReactGA from "react-ga4";

function App() {
  const [facebookLink, setFacebookLink] = useState(
    "https://www.facebook.com/profile.php?id=100064709225350"
  );
  const [animate, setAnimate] = useState(false);

  // Function to trigger and reset animation
  const triggerAnimation = () => {
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 3500); // Match this timeout with the animation duration
  };

  return (
    <Router>
      <AppContent
        facebookLink={facebookLink}
        setFacebookLink={setFacebookLink}
        animate={animate}
        triggerAnimation={triggerAnimation}
      />
    </Router>
  );
}

function AppContent({
  facebookLink,
  setFacebookLink,
  animate,
  triggerAnimation,
}) {
  const location = useLocation();

  // Google Analytics
  useEffect(() => {
    // Initialize GA4
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

    // Track the page view
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <div className="flex flex-col relative min-h-screen overflow-hidden">
      <div className="flex flex-col min-h-screen relative z-10 justify-between">
        <Navbar />
        <div className="">
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  setFacebookLink={setFacebookLink}
                  triggerAnimation={triggerAnimation}
                />
              }
            />
            <Route path="/about" element={<About />} />
            <Route path="/events" element={<Events />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/register" element={<Register />} />
            <Route path="/buytickets" element={<BuyTickets />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
        <Footer facebookLink={facebookLink} animate={animate} />
      </div>
      <Background />
    </div>
  );
}

export default App;
